import React from "react";
import oilIcon from "../assets/images/icons/oil.svg";
import brakesIcon from "../assets/images/icons/brakes.svg";
import transmissionIcon from "../assets/images/icons/transmission.svg";
import tireIcon from "../assets/images/icons/tire.svg";
import carwashIcon from "../assets/images/icons/carwash.svg";
import engineIcon from "../assets/images/icons/engine.svg";
// Make sure the paths to your images are correct

function Services() {
  return (
    <section
      id="services"
      className="theme-repair-services section-padding"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="section-title">
              <h2>EXEMPEL PÅ TJÄNSTER</h2>
              <h5>
                Här nedan förljer ett axplock av de tjänster vi utför. Tveka
                inte att kontakta oss med just ditt ärende
              </h5>
            </div>
          </div>
        </div>
        <div className="row d-flex align-items-center">
          {/* Single Service */}
          {renderService(
            oilIcon,
            "OLJEBYTE",
            "Bevara motorns vitalitet med regelbundna oljebyten. En välmående motor inleds med ett fräscht oljebyte. Besök oss för en snabb och effektiv service som håller din bil i toppskick längre. Vid vanliga motorproblem som överhettning eller oljeläckage, är en professionell diagnos och möjliga oljebyten avgörande."
          )}
          {renderService(
            brakesIcon,
            "ABS-BROMSAR",
            "Säkerhet prioriteras med vår ABS-bromsservice. Vårt team av skickliga mekaniker ser till att dina ABS-bromsar fungerar som de ska, så att du kan köra säkert oavsett omständigheter. Vid bekymmer som bromsslitage eller oväntade bromsreaktioner, erbjuder vår service en noggrann undersökning och eventuell reparation."
          )}
          {renderService(
            transmissionIcon,
            "AUTOMATLÅDOR",
            "En spolmaskin möjliggör snabb och effektiv utbytning av all olja i automatlådan, en process som traditionellt är tidskrävande. Vid vanliga växellådsfel som slirning, missljud eller dåliga växlingar, är professionell felsökning och möjliga växellådsbyten nödvändiga, där en kvalificerad verkstad kan bistå med expertis från diagnos till installation och eftervård."
          )}
          {renderService(
            tireIcon,
            "DÄCK & FÄLGAR",
            "Rätt däcktryck, rätt väg. Vi erbjuder däckrotation, balansering och nya däck från ledande märken för att hålla din bil säker och effektiv på vägen. Välkommen in till oss."
          )}
          {renderService(
            carwashIcon,
            "DÄCKHOTELL",
            "Bekvämt och säkert förvaring av dina däck. Slipp bekymret med däckförvaring. Vårt däckhotell erbjuder säker och professionell förvaring av dina däck mellan säsongerna."
          )}
          {renderService(
            engineIcon,
            "MOTORLAMPAN LYSER",
            "Inga problem. Vi gör en snabb och effektiv felsökning med modern teknik. Väkommen in så hjälper vi dig oavsett vad problemet är så garanterar vi att vi gör vårt bästa för dig."
          )}
        </div>
      </div>
    </section>
  );
}

function renderService(iconSrc, title, description) {
  return (
    <div
      className="col-lg-4 col-md-4 col-sm-12 wow kar-theme-single-service fadeInLeft text-center"
      data-wow-delay="0.2s"
    >
      <div className="kar-theme-single-service-card">
        <img
          src={iconSrc}
          alt="{title}"
        />
        <h5>{title}</h5>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default Services;
