import React, { useState } from "react";
import logo from "../assets/images/MR_auto_logga.png"; // Import the image

function Nav() {
  const [navbarClasses, setNavbarClasses] = useState(
    "navbar navbar-b navbar-expand-lg"
  );

  const handleToggle = () => {
    if (navbarClasses.includes("navbar-reduce")) {
      setNavbarClasses("navbar navbar-b navbar-expand-lg navbar-trans");
    } else {
      setNavbarClasses(
        "navbar navbar-b navbar-expand-lg navbar-trans navbar-reduce"
      );
    }
  };

  return (
    <header
      id="kar-theme-menu"
      className="kar-theme-menu-header-navber-area"
    >
      <nav
        className={navbarClasses}
        id="mainNav"
      >
        <div className="container">
          <a
            className="navbar-brand js-scroll"
            href="/"
          >
            <img
              src={logo}
              alt="logo"
            />
          </a>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarDefault"
            aria-controls="navbarDefault"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleToggle}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div
            className="navbar-collapse collapse justify-content-end"
            id="navbarDefault"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  className="nav-link js-scroll active"
                  href="#slider-home"
                >
                  Start
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link js-scroll"
                  href="#services"
                >
                  Tjänster
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  className="nav-link js-scroll"
                  href="#contact"
                >
                  Kontakt
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Nav;
