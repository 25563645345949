import React from "react";
import logo from "../assets/images/MR_auto_logga.png"; // Importing the logo image
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faMap, faPhone } from "@fortawesome/free-solid-svg-icons";

function Footers() {
  return (
    <footer
      id="footer"
      className="footer-section"
    >
      <div className="container">
        <div className="footer-content">
          <div className="row">
            <div className="col-xl-4 col-lg-4 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <a
                    href="/"
                    className="logo d-flex align-items-center"
                  >
                    <img
                      src={logo}
                      alt="MR AutoService"
                    />
                  </a>
                </div>
                <div className="footer-text">
                  <p>Du kan även hitta oss på följande sociala medier</p>
                </div>
                <div className="footer-social-icon">
                  <a href="https://www.facebook.com/MRAutoservice">
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="facebook-bg"
                    />
                  </a>
                  <a href="https://www.instagram.com/mrautoservice/">
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="instagram-bg"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Kontakt:</h3>
                </div>
                <div className="contact-info">
                  <h6>Adress:</h6>
                  <p>
                    <FontAwesomeIcon icon={faMap} /> Verkstadsgatan 12, 434 42
                    Kungsbacka
                  </p>
                  <p>
                    <a
                      href="https://www.google.com/maps/dir/58.4715051,11.6801923/MR+Autoservice+AB,+Verkstadsgatan+12,+434+42+Kungsbacka/@57.97085,11.257268,9z/data=!3m1!4b1!4m10!4m9!1m1!4e1!1m5!1m1!1s0x464fe886ffffffff:0x34f5797496b92ec4!2m2!1d12.085641!2d57.4797655!3e0?entry=ttu"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Vägbskrivning
                    </a>
                  </p>
                </div>
                <div className="contact-info">
                  <h6>Telefon:</h6>
                  <p>
                    <FontAwesomeIcon icon={faPhone} />{" "}
                    <a href="tel:030010636">0300-106 36</a>
                  </p>
                </div>
                <div className="contact-info">
                  <h6>Email:</h6>
                  <p>
                    <FontAwesomeIcon icon={faEnvelope} />{" "}
                    <a href="mailto:mr@lagadinbil.se">mr@lagadinbil.se</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footers;
