import React from "react";
import workshopBg from "../assets/images/workshop_bg.png"; // Adjust path if necessary

function Header() {
  return (
    <header
      id="slider-home"
      className="slider bg-b"
    >
      <div className="swiper-container parallax-slider">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div
              className="bg-img valign"
              style={{ backgroundImage: `url(${workshopBg})` }}
              data-overlay-dark="6"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="caption text-center">
                      <h2>Bilreparationer, service & automatlådor</h2>
                      <h1>MR AutoService</h1>
                      <p style={{ maxWidth: "700px", margin: "0 auto" }}>
                        Med över 10 år av erfarenhet ser vi till att din bil får
                        den bästa möjliga servicen. Vare sig det är
                        rutinunderhåll eller akuta reparationer, är vårt team av
                        certifierade mekaniker här för att erbjuda snabb och
                        pålitlig service. Boka din tid idag och upplev
                        skillnaden med personlig, professionell service i
                        Kungsbacka!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
